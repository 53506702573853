body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  background-color: #f4f4f4 !important
}

img.platform_logo {
  height: 60px;
}

img.logo {
  height: 50px;
  margin-top: 8px;
}

nav a {
  text-decoration: none;
  cursor: pointer;
}


pre {
  /*font-family: "Courier New", Courier, "Lucida Console", Monaco, monospace;*/
  /*background-color: #f4f4f4;*/
  padding: 15px;
  overflow-x: auto;
  white-space: pre-wrap;
  font-size: 0.8em;
  text-align: left;
  /* Ensure text is left-aligned */
}

.MuiCard-root {}

.MuiCard-root>.MuiButtonBase-root:hover {
  background-color: rgba(164, 55, 219, 0.1) !important;
  color: black;
}

.MuiTouchRipple-child {
  background-color: rgba(164, 55, 219, 1) !important;
  opacity: 1;
}

/*
.MuiAppBar-root{
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}*/